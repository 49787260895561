// import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import FormImg from '../../../assets/question-groups/Form.avif'
import './survey-form.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Checkbox,
    TextFieldProps,
    // Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Colors, Genders, Sizes } from '../../../constants/constants';
import { useEffect, useState } from 'react';
import modalFormSlice, { IModal } from '../../../store/login-modal-form/login-modal-form';
import { LoginModal } from '../../navbar/login/login-modal';
import { GetFamilyHistory, InsertUpdateFamilyHistory, UpdateUserData } from '../../../store/login-modal-form/login-modal-form-actions';

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    gender?: Genders;
    phoneNumber?: string;
    email?: string;
    cardiovascular?: boolean;
    diabetes?: boolean;
    cancer?: boolean;
    cancerType?: string;
    highBloodPressure?: boolean;
    other?: boolean;
    otherConditions?: string;
    mentalIllness?: boolean;
}


export interface ISurveyFormProps {

}

export interface IValidationFrom{
    personalData?:{
        name?: string,
        surname?: string,
        dateOfBirth?: string,
        gender?: string, //enum from constants/constants.ts
        phoneNumber?: string,
    }
    // ,familyData:{
    //     cardiovascular?: string
    //     diabetes?: string,
    //     cancer?: string,
        
    // }
}

export const SurveyForm: React.FunctionComponent<ISurveyFormProps> = props => {
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth) || window.innerWidth
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userData: IModal = useSelector((state: RootState) => state.loginModal)
    const dispatch: any = useDispatch();
    // console.log(userData)
    const initialDate = userData.userData.dateOfBirth ? dayjs(userData.userData.dateOfBirth) : dayjs('1980-01-01');

    const [errors, setErrors] = useState<IValidationFrom>({
        personalData:{
            name: "",
            surname: "",
            dateOfBirth: "",
            // gender: Genders.male, 
            phoneNumber: "",
        }
    });

    const validation = (): void => {
        const user = userData.userData
        const pData = errors.personalData || {}

        if (!user.firstName) {
            pData.name = 'Name is required.';

        } else if (user.firstName.length < 2) {
            pData.name = 'Name must be at least 2 characters long.';
        } else if (!/^[a-zA-Z]+$/.test(user.firstName)) {
            pData.name = 'Name cannot contain numbers or special characters.';
        } else {
            pData.name = undefined; // Clear error if valid
        }

        if (!user.lastName) {
            pData.surname = 'Surname is required.';
      
        } else if (user.lastName.length < 2) {
            pData.surname = 'Surname must be at least 2 characters long.';
        } else if (!/^[a-zA-Z]+$/.test(user.lastName)) {
            pData.surname = 'Surname cannot contain numbers or special characters.';
        } else {
            pData.surname = undefined;
        }

        if (!user.dateOfBirth) {
            pData.dateOfBirth = 'Date of Birth is required.';
      
        } else {
            pData.dateOfBirth = undefined;
        }

        if (!user.gender) {
            pData.gender = 'Gender is required';
        } else {
            pData.gender = undefined;
        }

        if (!user.phoneNumber) {
            pData.phoneNumber = 'Phone Number is required.';
        } else if (!/^\d+$/.test(user.phoneNumber)) {
            pData.phoneNumber = 'Phone Number must contain only digits.';
        } else {
            pData.phoneNumber = undefined; // Clear error if valid
        }

        setErrors({...errors, personalData: pData})
    }

    useEffect(() => {
        validation();
        const timer = setTimeout(() => {
            dispatch(UpdateUserData(fetch, userData.userData));
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [
        userData.userData.firstName
        , userData.userData.lastName
        , userData.userData.dateOfBirth
        , userData.userData.gender
        , userData.userData.phoneNumber
        , userData.userData.email

    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            // console.log('EXE')
            dispatch(InsertUpdateFamilyHistory(fetch, userData.userData));
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [
        userData.userData.cardiovascular
        , userData.userData.diabetes
        , userData.userData.cancer
        , userData.userData.cancerType
        , userData.userData.highBloodPressure
        , userData.userData.other
        , userData.userData.otherConditions
        , userData.userData.mentalIllness
    ]);

    // useEffect(() => {
    //     console.log("userchange", userData.userData)
    //     if(userData.userData.id != undefined)
    //         dispatch(GetFamilyHistory(fetch, userData.userData.id))
    // },[userData.userData.id])

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={`mx-0 mx-sm-auto mt-0 border-0 d-flex justify-content-center ${browserWidth <= Sizes.BreakPoint ? 'flex-column' : ''}`}>
                <div className=' w-100 d-flex align-items-center' style={{ backgroundColor: 'rgba(219,239,239)' }}>
                    <img
                        src={FormImg}
                        alt="Form Image"
                        className="img-fluid w-100 circular-image px-1"

                    />
                </div>

                <div className="card border-0 w-100" style={{ backgroundColor: Colors.MMYellow2lighter }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Card sx={{ width: '100%', border: 'none', padding: 0, margine: 0, backgroundColor: Colors.MMYellow2lighter, boxShadow: '0 0 0 0px rgba(0,0,0,0.1)', }}>
                            <CardContent sx={{ borderTop: 'none', borderRight: 'none', borderBottom: 'none', borderLeft: 'none', backgroundColor: Colors.MMYellow2lighter }}>
                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                    <i className="far fa-file-alt fa-4x mb-3 text-primary"></i>
                                    <Typography variant="h6">
                                        <strong>OPŠTI PODACI</strong>
                                    </Typography>
                                </Box>

                                <Divider sx={{ mb: 2 }} />

                                <form className='border-0'>
                                    <TextField
                                        error={errors.personalData?.name != undefined}
                                        helperText={errors.personalData?.name}
                                        onChange={
                                            (e) => dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, firstName: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }
                                            ))
                                        }
                                        value={userData.userData.firstName}
                                        fullWidth
                                        label="Ime"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite ime i prezime"
                                        id="fullName"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <TextField
                                    error={errors.personalData?.surname != undefined}
                                    helperText={errors.personalData?.surname}
                                        onChange={
                                            (e) => dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, lastName: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }
                                            ))
                                        }
                                        value={userData.userData.lastName}
                                        fullWidth
                                        label="prezime"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite ime i prezime"
                                        id="fullName"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />


                                    {/* <TextField
                                    fullWidth
                                    label="Datum rođenja"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    margin="normal"
                                    id="birthDate"
                                    size='small'
                                /> */}

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={(date) =>
                                                dispatch(modalFormSlice.actions.setUserData({
                                                    userData: { ...userData.userData, dateOfBirth: date?.format('YYYY-MM-DD') },
                                                    IsAuthenticated: userData.IsAuthenticated
                                                }))
                                            }
                                            label="Datum rođenja"
                                            views={['year', 'month', 'day']}
                                            value={initialDate}
                                            // onChange={(newValue) => setSelectedDate(newValue)}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    margin: 'normal',
                                                    size: 'small',
                                                    InputLabelProps: {
                                                        shrink: true,
                                                    },
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>


                                    <FormControl component="fieldset" margin="normal">
                                        <FormLabel component="legend">Pol</FormLabel>
                                        <RadioGroup 
                                   
                                        value={userData.userData.gender} row aria-labelledby="gender" name="gender" onChange={(e) =>
                                            dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, gender: e.target.value === Genders.male ? Genders.male : Genders.female },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }))
                                        }>
                                            <FormControlLabel
                                                value={Genders.male}
                                                control={
                                                    <Radio
                                                    
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray',
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Muški"
                                            />
                                            <FormControlLabel
                                                value={Genders.female}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray',
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Ženski"
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                    <TextField
                                     error={errors.personalData?.phoneNumber != undefined}
                                     helperText={errors.personalData?.phoneNumber}
                                        onChange={(e) =>
                                            dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, phoneNumber: e.target.value },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }))
                                        }
                                        value={userData.userData.phoneNumber}
                                        fullWidth
                                        label="Kontakt telefon"
                                        type="tel"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite kontakt telefon"
                                        id="phone"
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                        disabled
                                        value={userData.userData.email}
                                        fullWidth
                                        label="Email adresa"
                                        type="email"
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Unesite email adresu"
                                        id="email"
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                    />

                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
                                        <strong>PORODIČNA ISTORIJA</strong>
                                    </Typography>

                                    <FormControl component="fieldset" margin="normal" >
                                        <FormLabel component="legend">
                                            Da li u vašoj porodici postoji istorija sledećih bolesti? (Označite ispod koju)
                                        </FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={userData.userData.cardiovascular}
                                                        onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, cardiovascular: !userData.userData.cardiovascular },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))}
                                                        sx={{
                                                            color: 'gray', // Default color
                                                            '&.Mui-checked': {
                                                                color: 'gray', // Selected color
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Kardiovaskularne bolesti"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={userData.userData.diabetes}
                                                        onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, diabetes: !userData.userData.diabetes },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))}
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray', // Custom selected color
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Dijabetes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={userData.userData.cancer}
                                                        onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, cancer: !userData.userData.cancer },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))}
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray', // Custom selected color
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Kancer (ukoliko da navedite koja vrsta kancera)"
                                            />
                                            <TextField
                                                onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                    userData: { ...userData.userData, cancerType: e.target.value },
                                                    IsAuthenticated: userData.IsAuthenticated
                                                }))}
                                                fullWidth
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                placeholder="Navedi..."
                                                sx={{ mb: 2 }}
                                                size="small"
                                                value={userData.userData.cancerType}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={userData.userData.highBloodPressure}
                                                        onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, highBloodPressure: !userData.userData.highBloodPressure },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))}
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray', // Custom selected color
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Visok krvni pritisak"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={userData.userData.other}
                                                        onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                            userData: { ...userData.userData, other: !userData.userData.other },
                                                            IsAuthenticated: userData.IsAuthenticated
                                                        }))}
                                                        sx={{
                                                            color: 'gray',
                                                            '&.Mui-checked': {
                                                                color: 'gray', // Custom selected color
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Ostalo (navedite):"
                                            />
                                            <TextField
                                                onChange={(e) => dispatch(modalFormSlice.actions.setUserData({
                                                    userData: { ...userData.userData, otherConditions: e.target.value },
                                                    IsAuthenticated: userData.IsAuthenticated
                                                }))}
                                                fullWidth
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                placeholder="Navedi..."
                                                sx={{ mb: 2 }}
                                                size="small"
                                                value={userData.userData.otherConditions}
                                            />
                                        </FormGroup>

                                    </FormControl>

                                    <FormControl component="fieldset" margin="normal">
                                        <FormLabel component="legend">
                                            Da li je neko od vaših bliskih rođaka imao ozbiljnu mentalnu bolest? (npr. depresija, šizofrenija)
                                        </FormLabel>
                                        <RadioGroup row aria-labelledby="mentalIllness" onChange={(e) =>
                                            dispatch(modalFormSlice.actions.setUserData({
                                                userData: { ...userData.userData, mentalIllness: e.target.value === "yes" ? true : false },
                                                IsAuthenticated: userData.IsAuthenticated
                                            }))
                                        }>
                                            <FormControlLabel value="yes" control={<Radio sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: 'gray',
                                                }
                                            }} />} label="Da" />
                                            <FormControlLabel value="no" control={<Radio sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: 'gray',
                                                }
                                            }} />} label="Ne" />
                                        </RadioGroup>
                                    </FormControl>

                                </form>
                            </CardContent>
                        </Card>
                    </Box>
                </div>
            </div>
        </Box>
    );
}

