
import Textarea from '@mui/joy/Textarea';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useEffect, useState } from "react";
import { IQuestion } from "../survey-questions";
import surveySlice from "../../../../store/survey/survey-slice";
import { IHealthAssesment } from "../../survey-start/survey-start";
import { SubmitAnswersInDatabase } from "../../../../store/survey/survey-actions";
import { Form, Row, Col } from 'react-bootstrap';
import '../answers/answer.css'
import { Box, FormControlLabel, Grid, Slide, Slider } from '@mui/material';



export interface IAnswerSelectionType {
    answerId: number,
    isSelected: boolean
}

export interface ISubmitAnswersModel {
    questionId: number,
    healthAssesmentId: number,
    answers: IAnswerSelectionType[]
}

export interface IAnswer {
    id: number,
    questionId: number,
    orderNo: number,
    text: string,
    isActive: boolean,
    isSelected: boolean,
    isSavedInDatabase: boolean,
    isAnswered: boolean
}

export interface IAnswerProps {
    question: IQuestion,
    inputType: string | 'default'
}

export const Answer: React.FunctionComponent<IAnswerProps> = (props) => {
    const { question, inputType } = props;
    // console.log(question)
    const answers: IAnswer[] = useSelector((state: RootState) =>
        state.survey.Answers
    );
    const healthAssesment: IHealthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment);
    const dispatch: any = useDispatch();

    const [filteredAnswers, setFilteredAnswers] = useState<IAnswer[]>(answers.filter(item => item.questionId === question.id));
    const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(filteredAnswers.find(x => x.isSelected)?.id || null);
    const [selectedAnswersIds, setSelectedAnswersIds] = useState<Set<number>>(new Set(filteredAnswers.filter(x => x.isSelected).map(x => x.id)));

    const [reduxChanged, setReduxChanged] = useState<boolean>(false);
    // const [error, setError] = useState<string>("");
    // const [limit, setLimit] = useState<boolean>(selectedAnswersIds.size < question.maxSelect);
    useEffect(() => {
        setFilteredAnswers(answers.filter(item => item.questionId === question.id));
    }, [answers, question.id]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const answerId = Number(event.target.value);
        // console.log(answerId)
        const findAnswer: IAnswer[] = filteredAnswers.filter(x => x.id == answerId)
        // console.log(findAnswer)
        const findNumberOfAnswersOfQuestion = filteredAnswers.filter(x => x.questionId == findAnswer[0].questionId).length
        // console.log(findNumberOfAnswersOfQuestion)

        if (findNumberOfAnswersOfQuestion == 2) {
            setSelectedAnswersIds(prevSelected => {
                const newSelected = new Set(prevSelected);

                newSelected.clear()
                newSelected.add(answerId)
                if(prevSelected.has(answerId)) newSelected.clear()
                return newSelected;
            });
        }
        else {
            setSelectedAnswersIds(prevSelected => {
                const newSelected = new Set(prevSelected);
                if (newSelected.has(answerId)) {
                    newSelected.delete(answerId);
                } else if (selectedAnswersIds.size < question.maxSelect) {

                    newSelected.add(answerId);

                }
                // console.log('newSelected', newSelected)
                return newSelected;
            });
        }



    };

    if (filteredAnswers.length == 0) {

        return (
            <div className="bg-white p-0 border rounded w-100">
                <Row sx={{ width: '100%  !important', margin: 0 }}>
                    <Col xs={12} sm={12} md={12} sx={{ padding: 0, width: '100% !important' }}>
                        <Textarea
                            sx={{ width: '100% !important', backgroundColor: 'white' }} //l
                            color="neutral"
                            disabled={false}
                            minRows={3}
                            size="lg"
                            variant="plain"
                            placeholder="Odgovor..."
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    // const marks = [
    //     {
    //       value: 0,
    //       label: '0',
    //     },
    //     {
    //       value: 20,
    //       label: '20',
    //     },
    //     {
    //       value: 37,
    //       label: '37',
    //     },
    //     {
    //       value: 100,
    //       label: '100',
    //     },
    //   ];



    if (inputType == 'slider') {
        type Mark = {
            value: number;  // The position of the mark on the slider
            label?: string | React.ReactNode;  // Optional label displayed at the mark
        };

        // Dynamically generate marks from filteredAnswers
        const marks: Mark[] = filteredAnswers.map(x => ({
            value: x.id,
            label: x.text
        }));


        return (
            <div className="bg-white p-0 border rounded w-100">
                <Row sx={{ width: '100% !important', margin: 0, padding: 0 }}>
                    <Col xs={12} sm={12} md={12} sx={{ padding: 0, width: '100% !important' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px', mx: 1 }}>
                            <Slider
                                onChange={(event: Event, value: number | number[], activeThumb: number) => {
                                    // handleCheckboxChange(event); 
                                    const answer = filteredAnswers.find(x => x.id == value)
                                    // console.log(marks)
                                    // console.log(answer)
                                    if (answer) {
                                        dispatch(surveySlice.actions.setAnswerSlider(answer));
                                        dispatch(surveySlice.actions.setQuestion({ questionId: answer.questionId, isSavedInDB: false }));
                                    } else {
                                        console.warn("No answer found for the selected value:", value);
                                    }
                                }}
                                aria-label="Slider"
                                defaultValue={filteredAnswers.find(x => x.isSelected)?.id || 0}
                                step={1} // Makes the slider snap to the marks
                                marks={marks} // Dynamically generated marks
                                valueLabelDisplay="off" // Hides the value display on top
                                sx={{
                                    width: '90%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    color: 'grey',
                                }}
                                min={marks[0]?.value}
                                max={marks[marks.length - 1]?.value}
                            />
                        </Box>
                    </Col>
                </Row>
            </div>
        );
    }



    const twoColumns: boolean = filteredAnswers.length >= 6
    // if (limit) {
    // return (
    //     <div className="bg-white p-3 border rounded w-100">
    //         {/* {question.id} */}
    //         <Row>
    //             {filteredAnswers
    //                 .map((answer: IAnswer) => (
    //                     <Col xs={12} sm={12} md={twoColumns ? 6 : 12} key={answer.id} >
    //                         <Form.Check

    //                             type="checkbox"
    //                             color=""
    //                             label={`${answer.id}`}
    //                             value={answer.id}
    //                             checked={selectedAnswersIds.has(answer.id)}
    //                             onChange={(e) => {
    //                                 handleCheckboxChange(e); 
    //                                 dispatch(surveySlice.actions.setAnswer(answer));
    //                                 dispatch(surveySlice.actions.setQuestion({questionId: answer.questionId, isSavedInDB: false}));
    //                             }}
    //                             // onClick={handleMultipleSelectChange}
    //                         />
    //                     </Col>
    //                 ))}
    //         </Row>

    //     </div>
    // );
    return (
        <div className="bg-white p-3 border rounded w-100">
            <Grid container spacing={2}>
                {filteredAnswers.map((answer: IAnswer) => (
                    <Grid item xs={12} sm={twoColumns ? 6 : 12} key={answer.id}>
                        <FormControlLabel
                            sx={{ display: 'flex', justifyContent: 'start' }}
                            control={
                                <Box sx={{ mx: 1 }}>
                                    <Form.Check
                                        checked={selectedAnswersIds.has(answer.id)}
                                        onChange={(e) => {
                                            handleCheckboxChange(e);
                                            dispatch(surveySlice.actions.setAnswer(answer));
                                            dispatch(surveySlice.actions.setQuestion({ questionId: answer.questionId, isSavedInDB: false }));
                                        }}
                                        value={answer.id}
                                    /></Box>
                            }
                            label={`${answer.text}`}
                            style={{ cursor: 'pointer' }}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );

};
