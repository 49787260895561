import React, { useEffect, useState } from 'react';
import "./survey-question-groups.css";
import { IQuestion, SurveyQuestions } from './survey-questions/survey-questions';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchQuestionGroups, fetchQuestions } from '../../store/survey/survey-actions';
import Alergije from '../../assets/question-groups/Alergije.jpg'
import Anestezija from '../../assets/question-groups/Anestezija.jpg'
import Lekovi from '../../assets/question-groups/Lekovi.jpg'
import PrevencijaiScreening from '../../assets/question-groups/prevencijaiscreening.webp'
import SimptomiBolesti from '../../assets/question-groups/simptomiBolesti.jpg'
import Srce from '../../assets/question-groups/Srce.jpg'
import Pluca from '../../assets/question-groups/Pluca.avif'
import Stetoskop from '../../assets/question-groups/Stetoskop.jpg'
import Endokrini from '../../assets/question-groups/Endokrini.jpg'
import NervniPoremecaji from '../../assets/question-groups/NervniPoremecaji.jpg'
import FizickaAktivnost from '../../assets/question-groups/FizickaAktivnost.jpg'
import SocijalniOdnosi from '../../assets/question-groups/SocijalniOdnosi.jpg'
import Promena from '../../assets/question-groups/Promena.avif'
import Emocije from '../../assets/question-groups/EmotionalWellbeing.avif'
import Ishrana from '../../assets/question-groups/Ishrana.avif'
import San from '../../assets/question-groups/San.jpg'
import Drugs from '../../assets/question-groups/Drugs.jpg'
import { Colors } from '../../constants/constants';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, Margin, Widgets } from '@mui/icons-material';
import { IAnswer } from './survey-questions/answers/answer';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/material/styles';


// export interface IUserAnswer {
//     id: number,
//     value: string
// }

export interface IQuestionGroup {
    id: number | undefined,
    name: string | undefined,
    inLevel: number | undefined,
    parentId: number | undefined,
    questions: any[] | undefined,
    subgroups: any[] | undefined,
    url: string | undefined
}

export interface IApiResponse<T> {
    Data: T,
    IsSuccess: boolean,
    Message: string,
    StatusCode: number
}

export interface IQuestionGroupProps {
    questionGroups?: IQuestionGroup[],
    questions?: IQuestion[],
    groupName?: string,
    categoryId?: number
}

export const QuestionGroups: React.FunctionComponent<IQuestionGroupProps> = props => {
    const { groupName, questionGroups, questions,categoryId } = props

    const [expandedCategory, setExpandedCategory] = useState<number | null>();
    //Ovde sam dodao logiku koja iskljucuje iz brojanja pitanja koja imaju pod pitanja kao na primer pitanje sa id = 50
    const numberOfAnsweredQuestions: number[] = useSelector((state: RootState) =>
        Array.from(
            new Set(
                state.survey.Answers
                    .filter((x: IAnswer) => x.isAnswered && (questions && questions.findIndex((q: IQuestion) => q.parentId == x.id) === -1))
                    .map((x: IAnswer) => x.questionId)
            )
        )
    );

    const hasProblem: IQuestion[] = useSelector((state: RootState) =>
        Array.from(
            new Set(
                state.survey.Questions
                    .filter(x => !x.isSavedInDB)
            )
        )
    );

    const theme = useTheme();
    // console.log('numberOfAnsweredQuestions',numberOfAnsweredQuestions)
    // const [completedGroup, setCompletedGroup] = useState<boolean>(false)
    // useEffect(() =>{
    //     console.log('Answered questions updated:', numberOfAnsweredQuestions);
    // },[numberOfAnsweredQuestions])
    // let toExpand = false;
    // const [toExpand, setToExpand] = useState<boolean>(false);

    // console.log('numberOfAnsweredQuestions', numberOfAnsweredQuestions)

    const handleCategoryClick = (index: number): void => {
        if (expandedCategory == index) {
            setExpandedCategory(null);
        }
        else {
            setExpandedCategory(index);
        }

    }

    type ImageMap = {
        [key: string]: string;
    };

    const imgs: ImageMap = {
        'alergije': Alergije,
        'anestezija': Anestezija,
        'lekovi': Lekovi,
        'prevencija i screening': PrevencijaiScreening,
        'simptomi bolesti': SimptomiBolesti,
        'kardiovaskularni sistem': Srce,
        'respiratorni sistem': Pluca,
        'ostala medicinska istorija': Stetoskop,
        'endokrini': Endokrini,
        'neurološka oboljenja': NervniPoremecaji,
        'fizicka aktivnost': FizickaAktivnost,
        'socijalni odnosi': SocijalniOdnosi,
        'motivacija - da li ste spremni za promene': Promena,
        'emocionalno i mentalno zdravlje': Emocije,
        'ishrana': Ishrana,
        'san': San,
        'rizicne supstance': Drugs,
    };






    const Options = (): JSX.Element[] => {
        if (!questionGroups) return [];

        return questionGroups.map((group, i) => {
            const imgStyle = {
                width: 60,
                height: 60,
                backgroundImage: `url(${imgs[group.name?.toLowerCase() || 'def']})`,
                backgroundColor: '#f0f0f0',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '50%',
                mr: 2,
                border: '1px solid lightgrey',
                minWidth: '60px'
            };

            const imgStyle2 = {
                width: 350,
                height: 320,
                backgroundImage: `url(${imgs[group.name?.toLowerCase() || 'def']})`,
                backgroundColor: '#f0f0f0',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '50%',
                mr: 0,
                border: '1px dotted lightgrey',
                mb: 2
            };

            //Ovde se odredjuje broj odgovorenih pitanja i ukupan broj pitanja po kategorijama
            // toExpand = expandedCategory === i;
            const toExpand = expandedCategory === i;

            //Ovde sam dodao logiku koja iskljucuje iz brojanja pitanja koja imaju pod pitanja kao na primer pitanje sa id = 50
            //dakle ako je konkretno pitanje
            const numberOfQuestions = questions ? questions
                .filter(x => x.questionGroupId === group.id && questions.findIndex((q: IQuestion) => q.parentId == x.id) === -1)
                .map(q => q.questionGroupId) : [];

            const numberOfAnswered = questions ? questions
                .filter(question => question.questionGroupId === group.id && numberOfAnsweredQuestions.includes(question.id ? question.id : -1)) : [];
            // console.log(numberOfAnswered)
            const completedGroup = (numberOfAnswered ? numberOfAnswered.length : 0) == numberOfQuestions.length

            return (
                <div>
                    {/* Kako bi ste ocenili vase zdravlje? id === 13? */}
                    {(i === 0 && categoryId === 3) &&
                        <Paper sx={{ my: 1 , borderLeft: '5px solid grey', borderRadius: 2, borderTop: '1px solid white'}}>
                            <SurveyQuestions questionsFiltered={questions ? questions.filter(x => x.id === 13) : []}
                                completedGroup={completedGroup} />
                        </Paper>
                    }


                    <div className={`${'image-container'} p-0`} key={i}>

                        <Accordion
                            expanded={toExpand}
                            onChange={() => handleCategoryClick(i)}
                            sx={{ mb: 1, p: 0, backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter }} //accord color
                        >
                            <AccordionSummary
                                expandIcon={toExpand ? <ExpandLess /> : <ExpandMore />}
                                aria-controls={`panel${i}a-content`}
                                id={`panel${i}a-header`}

                            // onClick={()=> setToExpand(!toExpand)}
                            >
                                <div className={`ThisShouldDissapearWhenAccordionOpensDetails ${toExpand ? 'hidden' : 'fade-out'}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Box sx={imgStyle} />
                                        <Box sx={{ flexGrow: 0, }}>
                                            <Typography sx={{ fontSize: '13px' }}
                                            >{group.name?.toUpperCase()}</Typography>
                                            <Typography variant="body2" sx={{ fontSize: '13px' }}
                                            >
                                                {numberOfAnswered ? numberOfAnswered.length : 0}/{numberOfQuestions.length}
                                                {(
                                                    hasProblem.length > 0 &&
                                                    hasProblem.findIndex(x => x.questionGroupId != group.id)

                                                ) && <ErrorIcon sx={{ color: theme.palette.error.light }} />}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails sx={{ p: 0 }}>
                                <Box>
                                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
                                        {/* {group.id}  */}
                                        {group.name}
                                    </Typography>
                                </Box>
                                <Grid item xs={12} md={4} sx={{ width: '100%', display: 'flex', justifyContent: 'center', borderBottom: '1px dotted lightgrey' }}>
                                    <Box sx={imgStyle2} />
                                </Grid>

                                <SurveyQuestions questionsFiltered={questions ? questions.filter(x => x.questionGroupId === group.id) : []}
                                    completedGroup={completedGroup} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0 }}>
                                    <IconButton onClick={() => setExpandedCategory(null)}>
                                        <Typography variant="body2">ZATVORI</Typography>
                                    </IconButton>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            );
        });
    };


    return (
        <>
            {/* <Paper>{groupName}</Paper> */}
            <div >
                {/* <h3 className='text-center' >{groupName}</h3> */}

                {Options()}
            </div>
        </>

    );

};
